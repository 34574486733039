import React from "react"
import { graphql } from "gatsby"
import ListaPrensa from "../components/listaPrensa"
import Layout from "../components/layout"
import Contactanos from "../components/contactanos"

const Prensa = ({ location }) => {
    const pageTitle = (
        <span>
            Apariciones en Prensa de
            <br />
            <em>Open Green Road</em>
        </span>
    )

    return (
        <div style={{ backgroundColor: "#f2f2f2" }}>
            <Layout location={location} title={pageTitle}>
                <ListaPrensa />
                <div style={{ marginTop: "6rem" }}>
                    <Contactanos toPress={true} />
                </div>
            </Layout>
        </div>
    )
}

export default Prensa

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
